import { AddressDto } from 'types/client';

export const firstLetterUppercase = (text: string): string => {
  if (typeof text === 'string') {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  } else {
    return text;
  }
};

export const concatStrings = (elements: Array<string>): string => {
  const stringToReturn: string = elements.reduce(
    (accumulator, currentValue) => {
      if (currentValue) {
        if (accumulator) return `${accumulator} ${currentValue}`;
        else return currentValue;
      } else return accumulator;
    },
    ''
  );
  return stringToReturn;
};

export const getAddress = (address: AddressDto) => {
  const {
    address1,
    address2,
    address3,
  }: { address1: string; address2: string; address3: string } = address;
  if (!address1 && !address2 && !address3) return '';
  else {
    return firstLetterUppercase(concatStrings([address1, address2, address3]));
  }
};

export const pushIfNotExists = (table, value) => {
  if (table.indexOf(value) === -1) table.push(value);
};

export const isNumeric = (string) => {
  return /^[0-9]+$/.test(string);
};
