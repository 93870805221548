import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { UsersArrowTopIcon } from 'components/Icons/icons';
import { ICustomerDto } from 'types/client';
import { dateToStringNoTime, dateToStringWithTime } from 'utils/dateUtil';
import { firstLetterUppercase } from 'utils/utils';

const getGeneralSectionFields = (
  client: ICustomerDto
): (
  | {
      name: string;
      value: string;
    }
  | {
      name: string;
      value: ReactNode;
    }
)[] => [
  {
    name: 'Contact',
    value: `${firstLetterUppercase(
      client.firstName
    )} ${client.lastName.toUpperCase()}`,
  },
  { name: 'Ville de naissance', value: client.villeNais },
  { name: 'Pays de naissance', value: client.paysNais },
  {
    name: 'Date de naissance',
    value: dateToStringNoTime(client.individual?.birthDate),
  },
  {
    name: 'Enfants',
    value: (
      <Box sx={{ display: 'flex' }}>
        <Box>
          <UsersArrowTopIcon viewBox="0 0 22 20" />
        </Box>
        <Typography variant="body2" sx={{ marginLeft: 2 }}>
          Enfant {client.individual?.children.length.toString()}
        </Typography>
      </Box>
    ),
  },
  { name: 'Region', value: '' },
  {
    name: 'Date de création du client',
    value: dateToStringWithTime(client.dateCre),
  },
  { name: 'Apporteur client', value: '' },
];

export default getGeneralSectionFields;
