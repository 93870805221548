import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import ObjectLayout from 'layouts/Object/objectLayout';
import { ICustomerDto } from 'types/client';
import customerApi from 'api/customerApi';
import Header from './Header/header';
import getTabItems from './tabItems';
import userParamsApi from 'api/userParams';

type ClientMatchParams = {
  id: string;
};

const Client: React.FC = () => {
  const [client, setClient] = useState<ICustomerDto>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const matchs = useRouteMatch<ClientMatchParams>();
  const { id: customerNumber } = matchs.params;

  const getClient = async () => {
    setLoading(true);
    try {
      const res = await customerApi.getCustomerByCustomerNumber(
        parseInt(customerNumber)
      );
      setClient(res);
    } catch (error) {
      console.log('erreur', error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserParams = async () => {
    try {
      await userParamsApi.updateHistory(customerNumber, 'clients', new Date());
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateUserParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNumber]);

  if (!loading && client) {
    return (
      <ObjectLayout
        entity={client}
        header={Header}
        tabItems={getTabItems(client)}
      />
    );
  }
};

export default Client;
