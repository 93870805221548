// /** @jsxImportSource @emotion/react */
// /** @jsxRuntime classic */
// /** @jsxRuntime automatic */

import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import List from '@mui/material/List';

import { IMenuItem } from 'types/types';
import ListItem from 'components/ListItem/listItem';

type Props = {
  items: Array<IMenuItem>;
  selectedItem?: IMenuItem;
  onClick?: (item: IMenuItem) => void;
};

const Menu: React.FC<Props> = (props: Props) => {
  const { items, selectedItem, onClick } = props;
  const location = useLocation();

  const handleItemClick = (item: IMenuItem): void => {
    if (onClick) onClick(item);
  };

  const isActive = (item: IMenuItem): boolean => {
    if (selectedItem) return item.id === selectedItem.id;
    else
      return !!matchPath(location.pathname, {
        path: [item.link, `${item.link}/:id`],
        exact: true,
        strict: true,
      });
  };

  return (
    <List>
      {items.map((item: IMenuItem) => (
        <ListItem
          key={item.id}
          {...item}
          active={isActive(item)}
          onClick={handleItemClick}
        />
      ))}
    </List>
  );
};

export default Menu;
