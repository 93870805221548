/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React, { useRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/InputBase';
import Popover from '@mui/material/Popover';

import { IMenuItem } from 'types/types';
import { ChevronDownIcon } from 'components/Icons/icons';
import Menu from 'components/Menu/menu';
import styles from './style';

interface ISelect {
  items: Array<IMenuItem>;
  selectedItem: IMenuItem;
  onSelect: (item: IMenuItem) => void;
}

const Select: React.FC<ISelect> = (props: ISelect) => {
  const { items, onSelect, selectedItem } = props;
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(inputRef.current);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item: IMenuItem): void => {
    onSelect(item);
    handleClose();
  };

  return (
    <div css={styles.select} ref={inputRef}>
      <Input
        disabled
        css={styles.input}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClick}
            >
              <ChevronDownIcon />
            </IconButton>
          </InputAdornment>
        }
        value={selectedItem.label}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // sx={styles.popover}
      >
        <Menu
          items={items}
          selectedItem={selectedItem}
          onClick={handleSelect}
        />
      </Popover>
    </div>
  );
};

export default Select;
