/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import { getAddress, firstLetterUppercase } from 'utils/utils';
import { ICustomerDto } from 'types/client';
import {
  LinkExternal01,
  Phone,
  Mail01,
  MarkerPin1,
  UserCircleIcon,
  AlertError,
} from 'components/Icons/icons';
import Ellipsis from 'components/Ellipsis/ellipsis';
import clientApi from 'api/customerApi';
import { GED_TYPE_CATALOGUE_FLAG } from 'api/constants';
import { IGedCatalogue } from 'types/ged';
import styles from './style.js';

interface IHeaderProps {
  entity: ICustomerDto;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { entity: client } = props;
  const [identityDocuments, setIdentityDocuments] =
    useState<Array<IGedCatalogue>>(null);

  const getGedCatalogue = async () => {
    try {
      const res = await clientApi.getDocumentFromFlag(
        client.customerLink,
        GED_TYPE_CATALOGUE_FLAG
      );
      setIdentityDocuments(res);
    } catch (error) {
      console.log('erreur', error);
    }
  };

  // const handleOpenAddressMap = () => {
  //   window.open(
  //     'https://www.google.com/maps/place/' + getAddress(client.address)
  //   );
  // };

  useEffect(() => {
    getGedCatalogue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClient = () => {
    return (
      <>
        <div css={styles.headerTitle}>
          <UserCircleIcon css={styles.icon} />
          <div css={styles.container}>
            <Typography variant="h3">
              {`${client.title} ${firstLetterUppercase(
                client.lastName
              )} ${firstLetterUppercase(client.firstName)}`}
            </Typography>
          </div>
        </div>
        <div css={styles.container}>
          <Typography variant="subtitle3">{client.customerNumber}</Typography>
        </div>
      </>
    );
  };

  const renderChips = () => {
    return (
      <>
        <div css={styles.container}>
          <Chip
            label={
              <Typography variant="metadata">
                {firstLetterUppercase(client.establishment)}
              </Typography>
            }
            css={styles.chip}
          />
        </div>
      </>
    );
  };

  const renderInfos = () => {
    return (
      <div css={styles.headerCenter}>
        <MarkerPin1 css={styles.icon} />
        <a css={styles.link} href={'https://www.google.com/maps/place/' + getAddress(client.address)}
        // onClick={handleOpenAddressMap}
        >
          <Typography variant="subtitle3">
            {getAddress(client.address)}
          </Typography>
        </a>
        <Ellipsis />
        <Phone css={styles.icon} />
        <a css={styles.link} href={'tel:' + client.contact.mobilePhone}>
          <Typography variant="subtitle3">
            {client.contact.mobilePhone ||
              client.contact.phone1 ||
              client.contact.phone2}
          </Typography>
        </a>
        <Ellipsis />
        <Mail01 css={styles.icon} />
        <a css={styles.link} href={'mailto:' + client.contact.mail}>
          <Typography variant="subtitle3">{client.contact.mail}</Typography>
        </a>
      </div>
    );
  };

  const renderIdentityDocumentsLinks = () => {
    if (identityDocuments) {
      if (identityDocuments.length > 0) {
        return (
          <div css={styles.headerFooter}>
            {identityDocuments.map((pI) => (
              <div key={pI.idGedCatalogue}>
                <Button
                  variant="contained"
                  css={styles.headerButton}
                  sx={{ ':hover': { backgroundColor: '#FFFFFF' } }}
                >
                  {pI.libelleGedTypeDocFavori}
                  <LinkExternal01 css={styles.icon} />
                </Button>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div css={styles.alertContainer}>
            <Alert
              css={styles.alertError}
              severity="error"
              icon={<AlertError css={styles.iconAlert} />}
            >
              Pièces identitées non disponible en GED
            </Alert>
          </div>
        );
      }
    }
  };

  return (
    <div css={styles.headerLeft}>
      <div css={styles.headerTop}>
        {renderClient()}
        {renderChips()}
      </div>
      {renderInfos()}
      {renderIdentityDocumentsLinks()}
    </div>
  );
};

export default Header;
