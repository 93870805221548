import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Wallet01 = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 79 79" fill="none">
        <path
          d="M72.4163 27.9795H6.58301M6.58301 41.1462H18.2575C20.0356 41.1462 20.9247 41.1462 21.7844 41.2994C22.5474 41.4354 23.2918 41.6608 24.0022 41.9709C24.8025 42.3203 25.5423 42.8134 27.0217 43.7998L28.9359 45.0759C30.4154 46.0622 31.1552 46.5554 31.9555 46.9047C32.6658 47.2148 33.4102 47.4402 34.1733 47.5762C35.033 47.7295 35.9221 47.7295 37.7002 47.7295H41.2992C43.0773 47.7295 43.9663 47.7295 44.826 47.5762C45.5891 47.4402 46.3335 47.2148 47.0439 46.9047C47.8442 46.5554 48.5839 46.0622 50.0634 45.0759L51.9776 43.7998C53.4571 42.8134 54.1968 42.3203 54.9971 41.9709C55.7075 41.6608 56.4519 41.4354 57.215 41.2994C58.0747 41.1462 58.9637 41.1462 60.7419 41.1462H72.4163M6.58301 23.7003L6.58301 55.3003C6.58301 58.9873 6.58301 60.8308 7.30055 62.2391C7.93171 63.4778 8.93884 64.4849 10.1776 65.1161C11.5858 65.8336 13.4293 65.8336 17.1163 65.8337L61.883 65.8337C65.57 65.8337 67.4135 65.8337 68.8218 65.1161C70.0605 64.4849 71.0676 63.4778 71.6988 62.2391C72.4163 60.8308 72.4163 58.9873 72.4163 55.3003V23.7003C72.4163 20.0133 72.4163 18.1698 71.6988 16.7616C71.0676 15.5228 70.0605 14.5157 68.8218 13.8845C67.4135 13.167 65.57 13.167 61.883 13.167L17.1163 13.167C13.4293 13.167 11.5858 13.167 10.1776 13.8845C8.93884 14.5157 7.93172 15.5228 7.30055 16.7616C6.58301 18.1698 6.58301 20.0133 6.58301 23.7003Z"
          stroke="#717BBC"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default Wallet01;
