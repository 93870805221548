/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React, { useContext } from 'react';

import { ILayout, ITab, ObjectEntity } from 'types/types';
import { ICustomerDto } from 'types/client';
import { IPolicyLightDto } from 'types/policy';
import {
  INavigationState,
  NavigationContext,
} from 'contexts/navigationContext';
import Tabs from 'components/Tabs/tabs';
import Breadcrumb from 'components/Beadcrumb/breadcrumb';
import styles from './style.js';

interface IHeaderProps {
  entity: ObjectEntity;
}
interface IObjectLayout extends ILayout {
  entity?: ObjectEntity;
  header?: React.FC<IHeaderProps>;
  tabItems?: Array<ITab>;
}

const ObjectLayout: React.FC<IObjectLayout> = (props: IObjectLayout) => {
  const { entity, tabItems, header, children } = props;
  const { state } = useContext<INavigationState>(NavigationContext);
  const Header: React.FC<IHeaderProps> = header;

  return (
    <div css={styles.layoutContainer}>
      <Breadcrumb navigationEntries={state.entries} />
      {header && entity && <Header entity={entity} />}
      {tabItems && <Tabs tabs={tabItems} entity={entity} />}
      <div css={styles.contentWrapper}>{children}</div>
    </div>
  );
};

export default ObjectLayout;
