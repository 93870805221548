const dateToStringNoTime = (date: Date): string => {
  if (date == null) return '';
  const newDate = new Date(date);
  return (
    ('0' + newDate.getDate()).slice(-2) +
    '/' +
    ('0' + (newDate.getMonth() + 1)).slice(-2) +
    '/' +
    newDate.getFullYear()
  );
};

const dateToStringWithTime = (date: Date): string => {
  if (date == null) return '';
  const newDate = new Date(date);

  return (
    ('0' + newDate.getDate()).slice(-2) +
    '/' +
    ('0' + (newDate.getMonth() + 1)).slice(-2) +
    '/' +
    newDate.getFullYear() +
    ' ' +
    ('0' + newDate.getHours()).slice(-2) +
    ':' +
    ('0' + newDate.getMinutes()).slice(-2)
  );
};

export { dateToStringNoTime, dateToStringWithTime };
