import { css } from '@emotion/react';

const styles = {
  ellipsis: (theme) =>
    css`
      background-color: ${theme.palette.secondary[80]};
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-left: ${theme.spacing(4)};
      margin-right: ${theme.spacing(4)};
    `,
};

export default styles;
