import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';

import Client from './Client/client';
import Search from './Search/clientSearch';

const Clients: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <SecureRoute exact path={`${match.url}/:id`} component={Client} />
      <SecureRoute path={`${match.url}`} component={Search} />
    </Switch>
  );
};

export default Clients;
