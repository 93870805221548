const paymentSectionFields = [
  { name: 'Nom de banque', value: '' },
  { name: 'Domicilation', value: '' },
  { name: 'Titulaire du compte', value: '' },
  { name: 'IBAN', value: '' },
  { name: 'BIC', value: '' },
  { name: 'Banque', value: '' },
  { name: 'Guichet', value: '' },
  { name: 'N° de compte', value: '' },
  { name: 'Clé', value: '' },
];

export default paymentSectionFields;
