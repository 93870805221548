import { css } from '@emotion/react';

const styles = {
  boxContainer: (theme) => css`
    padding: 0;
    border: 1px solid ${theme.palette.secondary.main};
    border-radius: 8px;
    overflow: hidden;
  `,
  boxHeader: (theme) => css`
    display: flex;
    gap: 10px;
    align-items: center;
    padding: ${theme.spacing(4)};
    border-bottom: 1px solid ${theme.palette.secondary.main};
  `,
  boxLine: (theme) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    &: nth-of-type(2n) {
      background-color: ${theme.palette.secondary[20]};
    }
    &: nth-of-type(2n+1) {
      background-color: ${theme.palette.white.main};
    }
  `,
};

export default styles;
