import { css } from '@emotion/react';

const styles = {
  layoutContainer: css`
    display: flex;
    width: 100%;
  `,
  headerAndContent: (theme) => css`
    flex: 1;
  `,
  headerBar: (theme) =>
    css`
      height: 80px;
      background-color: ${theme.palette.secondary[10]};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
    `,
  leftBar: (theme) => css`
    width: 279px;
    padding-left: ${theme.spacing(8)};
    padding-right: ${theme.spacing(8)};
    background-color: ${theme.palette.secondary[10]};
  `,
  content: (theme) => css`
    background-color: ${theme.palette.white.main};
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
  `,
  logoWrapper: (theme) => css`
    width: 100%;
    text-align: center;
    margin-top: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(10)};
  `,
  menuWrapper: (theme) => css`
    width: 100%;
  `,
  buttonsWrapper: (theme) => css`
    width: fit-content;
    flex-wrap: nowrap;
    align-items: center;
  `,
  userImage: (theme) => css`
    height: 48;
    width: 48;
    border: 2px solid ${theme.palette.secondary.main};
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.secondary[80]};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 'bold';
  `,
};

export default styles;
