import { css } from '@emotion/react';

const styles = {
  tabsWrapper: (theme) => `
      width: 100%;
      padding-bottom: ${theme.spacing(4)};
      border-bottom: 1px;
      border-color: divider;
      margin-top: ${theme.spacing(8)}
  `,
  tabs: (theme) => css`
    & .MuiTabs-indicator {
      background-color: ${theme.palette.primary.main};
    }
  `,
  tab: (theme) => css`
    text-transform: inherit;
    height: 40px;
    min-height: 40px;
    color: ${theme.palette.secondary[80]};
    padding: ${theme.spacing(2)} ${theme.spacing(4)} !important;
    {...${theme.typography.subtitle3}}

    &.Mui-selected {
      color: ${theme.palette.secondary[80]};
    }
  `,
  badge: (theme) => css`
    margin-left: ${theme.spacing(4)} !important;
  `,
};

export default styles;
