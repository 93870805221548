import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const RefreshCcw02 = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M1 8C1 8 1.12132 7.15076 4.63604 3.63604C8.15076 0.12132 13.8492 0.12132 17.364 3.63604C18.6092 4.88131 19.4133 6.40072 19.7762 8M1 8V2M1 8H7M21 12C21 12 20.8787 12.8492 17.364 16.364C13.8492 19.8787 8.15076 19.8787 4.63604 16.364C3.39076 15.1187 2.58669 13.5993 2.22383 12M21 12V18M21 12H15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default RefreshCcw02;
