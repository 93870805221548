import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6 9L12 15L18 9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ChevronDownIcon;
