import React from 'react';
import { OktaSignInConfigType } from 'types/auth';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router';
import { Tokens } from '@okta/okta-auth-js';
import OktaSignInWidget from './OktaSignInWidget';

type Props = {
  oktaSignInConfig: OktaSignInConfigType;
};

const Login: React.FC<Props> = (props: Props) => {
  const { oktaSignInConfig } = props;

  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens: Tokens) => {
    console.log('success');
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log('error logging in', err);
  };

  if (!authState) return null;

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <OktaSignInWidget
      config={oktaSignInConfig}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};

export default Login;
