import { css } from '@emotion/react';

const styles = {
  linkText: (theme) =>
    css`
      color: ${theme.palette.secondary[80]};
    `,
  icon: (theme) =>
    css`
      stroke: ${theme.palette.secondary[80]};
      height: 32;
    `,
};

export default styles;
