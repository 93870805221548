import { objects } from 'constants/constants';
import { IMenuItem } from 'types/types';

const searchBarItems: Array<IMenuItem> = [
  objects.CLIENTS,
  objects.POLICIES,
  objects.SINISTERS,
  objects.RISKS,
  objects.RELATIONS,
];

export default searchBarItems;
