import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';

import GlobalLayout from 'layouts/Global/globalLayout';
import AccountingView from 'views/Accounting/accounting';
import ClientsView from 'views/Clients/clients';
import ExportView from 'views/Export/export';
import GedView from 'views/Ged/ged';
import HomeView from 'views/Home/home';
import MailsView from 'views/Mails/mails';
import ManagementView from 'views/Management/management';
import PageNotFound from 'views/PageNotFound/PageNotFound';
import PoliciesView from 'views/Policies/policies';
import QuotesView from 'views/Quotes/quotes';
import RelationsView from 'views/Relations/relations';
import RisksView from 'views/Risks/risks';
import SinistersView from 'views/Sinisters/sinisters';
import TeamManagementView from 'views/TeamManagement/teamManagement';

const SecureRoutes: React.FC = () => {
  return (
    <GlobalLayout>
      <Switch>
        <SecureRoute exact path="/" component={HomeView} />
        <SecureRoute path="/gestion-equipe" component={TeamManagementView} />
        <SecureRoute path="/clients" component={ClientsView} />
        <SecureRoute path="/polices" component={PoliciesView} />
        <SecureRoute path="/sinistres" component={SinistersView} />
        <SecureRoute path="/risques" component={RisksView} />
        <SecureRoute path="/relations" component={RelationsView} />
        <SecureRoute path="/courrier" component={MailsView} />
        <SecureRoute path="/comptabilite" component={AccountingView} />
        <SecureRoute path="/devis" component={QuotesView} />
        <SecureRoute path="/ged" component={GedView} />

        <SecureRoute path="/export" component={ExportView} />
        <SecureRoute path="/administration" component={ManagementView} />
        <Route render={() => <PageNotFound />} />
      </Switch>
    </GlobalLayout>
  );
};

export default SecureRoutes;
