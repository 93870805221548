import React from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const SummarySkeleton: React.FC = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={350} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={350} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={350} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={350} />
      </Grid>
    </Grid>
  );
};

export default SummarySkeleton;
