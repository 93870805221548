import React, { useState } from 'react';
import Table from 'components/Table/table';
import {
  ICustomerClaimsOutput,
  ICustomerClaimsOutputContent,
} from 'types/claims';
import CloudRaining01Icon from 'components/Icons/cloudRaining01';
import clientApi from 'api/customerApi';
import { ICustomerDto } from 'types/client';

import columns from './columns';

const NO_RESULT_MESSAGE = 'Aucun sinistre pour ce client';

interface IClaimsProps {
  entity: ICustomerDto;
}

const Claims: React.FC = (props: IClaimsProps) => {
  const { entity: client } = props;
  const [claims, setClaims] = useState<Array<ICustomerClaimsOutputContent>>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const getRowId = (row: ICustomerClaimsOutputContent) => row.nsin;

  const getClaims = async (currentPage, pageSize) => {
    setLoading(true);
    setClaims([]);

    try {
      const claimsResponse: ICustomerClaimsOutput =
        await clientApi.getClaimsByCustomerNumber(
          client.customerNumber,
          currentPage,
          pageSize
        );
      setClaims(claimsResponse.content);
      setTotal(claimsResponse.totalElements);
    } catch (error) {
      console.log('ERROR - GetColumnsByCustomerNumber', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Table
      columns={columns}
      rows={claims || []}
      getRows={getClaims}
      getRowId={getRowId}
      totalRows={total}
      noResultMessage={NO_RESULT_MESSAGE}
      noResultIcon={CloudRaining01Icon}
      loading={loading}
    />
  );
};

export default Claims;
