import palette from './palette';
import typography from './typography';

const components = {
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: palette.secondary[20],
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: 24,
        minHeight: 24,
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        padding: '0 !important',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: 'transparent',
        stroke: palette.secondary[80],
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 'inherit',
      },
      head: {
        backgroundColor: palette.secondary[20],
        borderBottom: `2px solid ${palette.secondary.main}`,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        border: `2px solid ${palette.secondary.main}`,
        borderRadius: 8,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette.secondary.main}`,
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        border: `1px solid ${palette.error.main}`,
        backgroundColor: `${palette.error[10]}`,
        color: `${palette.secondary[80]}`,
        borderRadius: 8,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        padding: 8,
        borderRadius: 8,
        border: `1px solid ${palette.secondary[30]}`,
        marginTop: 4,
        boxShadow: 'none',
        '& .MuiMenuItem-root': {
          ...typography.body2,
          borderRadius: 8,
          padding: '8px 16px !important',
          color: palette.secondary[80],

          '&.Mui-selected': {
            backgroundColor: palette.secondary.main,
            color: palette.white.main,

            '&: hover': {
              backgroundColor: palette.secondary[20],
              color: palette.secondary[80],
            },
          },
          '&: hover': {
            backgroundColor: palette.secondary[20],
          },
        },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: `2px solid ${palette.secondary.main}`,
        '&: focus': {
          outline: 'none',
        },
      },
      columnHeaders: {
        borderBottom: `2px solid ${palette.secondary.main}`,
      },
      columnHeaderTitle: {
        whiteSpace: 'break-spaces',
        textOverflow: 'clip',
        lineHeight: '20px',
      },
      columnHeader: {
        backgroundColor: `${palette.secondary[20]}`,
        '&: focus': {
          outline: 'none',
        },
      },
      columnSeparator: {
        display: 'none',
      },
      cell: {
        borderBottom: `1px solid ${palette.secondary.main}`,
        minHeight: '58px !important',
        '&: focus': {
          outline: 'none',
        },
      },
      cellContent: {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
      },
      row: {
        '&: hover': {
          cursor: 'pointer',
        },
      },
      menu: {
        '& > .MuiPaper-root': {
          padding: 8,
          borderRadius: 8,
          border: `1px solid ${palette.secondary[30]}`,
          marginTop: 4,
          boxShadow: 'none',
          '& .MuiMenuItem-root': {
            ...typography.body2,
            borderRadius: 8,
            padding: '8px 16px !important',
            color: palette.secondary[80],
          },
        },
      },
      paper: {
        borderRadius: 8,
      },
      panelWrapper: {
        padding: 8,
        borderRadius: 8,
        border: `1px solid ${palette.secondary[30]}`,
        overflow: 'hidden',
      },
      filterForm: {
        gap: '10px',

        '& .MuiInput-input': {
          textAlign: 'center',
        },
      },
    },
  },
};

export default components;
