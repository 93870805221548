import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import typography from 'theme/typography';
import palette from 'theme/palette';
import components from 'theme/components';

const spacing = 4;

const theme = createTheme(
  {
    palette,
    typography,
    spacing,
    components,
  },
  frFR
);

export default theme;
