/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { SvgIconProps, Typography, Box, Skeleton } from '@mui/material';

import { File06Icon, FileX02Icon } from 'components/Icons/icons';
import styles from './style.js';

interface ITable {
  title?: string;
  columns: GridColDef[];
  rows: object[];
  onRowClick?: (params: GridRowParams) => void;
  getRowId: (row: unknown) => string | number;
  getRows: (page: number, pageSize: number) => void;
  getRowsTrigger?: string;
  totalRows: number;
  nbRowsPerPage?: number;
  noResultMessage: string;
  noResultIcon: React.FC<SvgIconProps>;
  loading: boolean;
  filterModel?: GridFilterModel;
}

const Table: React.FC<ITable> = (props: ITable) => {
  const {
    title,
    columns,
    rows,
    getRowId,
    getRows,
    onRowClick,
    getRowsTrigger,
    totalRows,
    nbRowsPerPage,
    noResultMessage,
    noResultIcon,
    loading,
    filterModel,
  } = props;

  const [currentPageSize, setCurrentPageSize] = useState<number>(
    nbRowsPerPage || 25
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const NoResultIcon = noResultIcon;

  const handlePageSizeChange = (size: number) => {
    setCurrentPageSize(size);
    getRows(currentPage, size);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getRows(page, currentPageSize);
  };

  const renderNoRowsOverlay = () => {
    return (
      <div css={styles.overlayWrapper}>
        <div css={styles.overlay}>
          <div css={styles.circle}>
            <div css={styles.circleContent}>
              <File06Icon css={styles.fileIcons} />
              <FileX02Icon css={styles.fileIcons} />
              <NoResultIcon css={styles.cloudIcon} />
            </div>
          </div>
          <div css={styles.textWrapper}>
            <Typography variant="h3" css={styles.text}>
              {noResultMessage}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadingOverlay = () => {
    return (
      <>
        {[...Array(currentPageSize)].map((item, index) => (
          <Skeleton
            key={index}
            height={58}
            variant="rectangular"
            sx={{ my: 1, mx: 1 }}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    getRows(currentPage, currentPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRowsTrigger]);

  return (
    <>
      {title && (
        <Typography mb={5} variant="h3">
          {title}
        </Typography>
      )}
      <Box sx={{ height: 720, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          pageSize={currentPageSize}
          page={currentPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[5, 25, 50, 100]}
          getRowHeight={() => 'auto'}
          loading={loading}
          components={{
            NoRowsOverlay: renderNoRowsOverlay,
            NoResultsOverlay: renderNoRowsOverlay,
            LoadingOverlay: renderLoadingOverlay,
          }}
          disableColumnSelector
          rowCount={totalRows}
          paginationMode="server"
          filterModel={filterModel}
          onRowClick={onRowClick}
        />
      </Box>
    </>
  );
};

export default Table;
