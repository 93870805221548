import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BarChart07Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V3M7 10.5V17.5M11.5 5.5V17.5M16 10.5V17.5M20.5 5.5V17.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default BarChart07Icon;
