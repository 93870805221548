import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { RefreshCcw02Icon } from 'components/Icons/icons';
import { dateToStringWithTime } from 'utils/dateUtil';
import { ICustomerDto } from 'types/client';
import { Chip } from '@mui/material';

type FinscanStatus = {
  label: string;
  color: 'default' | 'error' | 'success' | 'warning';
};

const getFinscanStatus = (finscanResult: string) => {
  const finscanStatusObj: FinscanStatus = { label: 'Aucun', color: 'default' };

  switch (finscanResult) {
    case 'PASS':
      finscanStatusObj.label = 'Passé';
      finscanStatusObj.color = 'success';
      break;
    case 'PENDING':
      finscanStatusObj.label = 'En attente';
      finscanStatusObj.color = 'warning';
      break;
    case 'FAILED':
      finscanStatusObj.label = 'En erreur';
      finscanStatusObj.color = 'error';
      break;
    default:
      return '-';
  }

  return (
    <Chip
      sx={{ color: '#FFFFFF' }}
      size="small"
      label={finscanStatusObj.label}
      color={finscanStatusObj.color}
    />
  );
};

const getComplianceSectionFields = (client: ICustomerDto, refreshFinscan) => {
  return [
    {
      name: 'Exclusion CNIL',
      value: client.cnilExclusion === 1 ? 'Oui' : 'Non',
    },
    { name: 'Consentement', value: '' },
    {
      name: (
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2" sx={{ marginRight: 4 }}>
            Finscan au {dateToStringWithTime(client.finscanDate)}
          </Typography>
          <Box
            onClick={refreshFinscan}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton size="small" sx={{ marginRight: 2 }}>
              <RefreshCcw02Icon />
            </IconButton>
            <Typography variant="body2">{' Rafraîchir'}</Typography>
          </Box>
        </Box>
      ),
      value: getFinscanStatus(client.finscanStatus),
    },
  ];
};

export default getComplianceSectionFields;
