import React, { useState } from 'react';

import Table from 'components/Table/table';
import clientApi from 'api/customerApi';
import { ICustomerDto } from 'types/client';
import {
  ICustomerReceiptsOutput,
  ICustomerReceiptsOutputContent,
} from 'types/receipts';
import columns from './columns';
import { Wallet01Icon } from 'components/Icons/icons';

const NO_RESULT_MESSAGE = 'Aucune quittance pour ce client';

interface IReceiptsProps {
  entity: ICustomerDto;
}

const Receipts: React.FC = (props: IReceiptsProps) => {
  const { entity: client } = props;
  const [receipts, setReceipts] = useState<
    Array<ICustomerReceiptsOutputContent>
  >([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const getRowId = (row: ICustomerReceiptsOutputContent) => row.numQuittance;

  const getReceipts = async (currentPage, pageSize) => {
    setLoading(true);
    setReceipts([]);

    try {
      const receiptsResponse: ICustomerReceiptsOutput =
        await clientApi.getReceiptsByCustomerNumber(
          client.customerNumber,
          currentPage,
          pageSize
        );
      setReceipts(receiptsResponse.content);
      setTotal(receiptsResponse.totalElements);
    } catch (error) {
      console.log('ERROR - GetReceiptsByCustomerNumber', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Table
      columns={columns}
      rows={receipts || []}
      getRows={getReceipts}
      getRowId={getRowId}
      totalRows={total}
      noResultMessage={NO_RESULT_MESSAGE}
      noResultIcon={Wallet01Icon}
      loading={loading}
    />
  );
};

export default Receipts;
