/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styles from './style.js';

interface SectionInformationProps {
  label: string;
  rows: Array<{ name: ReactNode; value: ReactNode }>;
  icon?: ReactNode;
  fullHeight?: boolean;
}

const SectionInformation: React.FC<SectionInformationProps> = (
  props: SectionInformationProps
) => {
  const { label, rows, icon, fullHeight = true } = props;
  const theme = useTheme();

  return (
    <Box
      css={styles.boxContainer}
      sx={{ height: fullHeight ? '100%' : 'inherit' }}
    >
      <Box css={styles.boxHeader}>
        <Box>{icon}</Box>
        <Typography variant="h3" sx={{ color: theme.palette.secondary[80] }}>
          {label}
        </Typography>
      </Box>
      {rows.map((row, index) => (
        <Box key={index} css={styles.boxLine}>
          <Box>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.secondary[80] }}
            >
              {row.name}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.secondary[80] }}
            >
              {row.value}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SectionInformation;
