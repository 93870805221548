import React from 'react';

import Tabs from 'components/Tabs/tabs';
import tabItems from './tabItems';

const TeamManagement: React.FC = () => {
  return <Tabs tabs={tabItems} />;
};

export default TeamManagement;
