declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    body3: React.CSSProperties;
    label: React.CSSProperties;
    labelbold: React.CSSProperties;
    metadata: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    body3?: React.CSSProperties;
    label?: React.CSSProperties;
    labelbold?: React.CSSProperties;
    metadata?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    body3: true;
    label: true;
    labelbold: true;
    metadata: true;
  }
}

const typography = {
  fontFamily: 'Inter',

  h1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 32,
    // lineHeight: 40,
  },
  h2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 28,
    // lineHeight: 36,
  },
  h3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    // lineHeight: 32,
  },
  h4: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    // lineHeight: 28,
  },
  subtitle1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    // lineHeight: 26,
  },
  subtitle2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    // lineHeight: 24,
  },
  subtitle3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    // lineHeight: 24,
    letterSpacing: 0.2,
  },
  subtitle4: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    // lineHeight: 18,
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    // lineHeight: 28,
  },
  body2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    // lineHeight: 24,
  },
  body3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 13,
    // lineHeight: 20,
    letterSpacing: 0.2,
  },
  label: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    // lineHeight: 16,
  },
  labelBold: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 13,
    // lineHeight: 16,
  },
  metadata: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 11,
    // lineHeight: 16,
  },
};

export default typography;
