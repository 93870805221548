import axiosClient from './axiosClient';
import { BASE_URL_USER_PARAMS } from 'api/routes';
import { entity } from 'types/userParams';
import { ICustomerSearch } from 'types/client';

const userParamsApi = {
  updateHistory: async (customerNumber: string, entity: string, date: Date) => {
    const url = `${BASE_URL_USER_PARAMS}/history`;

    return axiosClient.post(url, {
      id: customerNumber,
      entity,
      date,
    });
  },
  getHistory: async (entity: entity): Promise<ICustomerSearch> => {
    const url = `${BASE_URL_USER_PARAMS}/history?entity=${entity}`;
    return axiosClient.get(url);
  },
};

export default userParamsApi;
