import { css } from '@emotion/react';

const styles = {
  overlayWrapper: (theme) => css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(4)};
  `,
  overlay: (theme) => css`
    width: 282px;
    height: 350px;
    border: 2px solid ${theme.palette.secondary[40]};
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.palette.secondary[10]};
  `,
  circle: (theme) => css`
    height: 166;
    width: 166;
    border-radius: 50%;
    border: 2px solid ${theme.palette.secondary[40]};
    background-color: ${theme.palette.secondary[20]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.spacing(5)};
  `,
  circleContent: () => css`
    position: relative;
  `,
  fileIcons: (theme) => css`
    height: 75px !important;
    width: 75px !important;
    stroke: ${theme.palette.secondary[30]};
    &: nth-of-type(1) {
      transform: rotate(-25deg);
    }
    &: nth-of-type(2) {
      transform: rotate(25deg);
    }
  `,
  cloudIcon: (theme) => css`
    position: absolute;
    height: 75px !important;
    width: 75px !important;
    stroke: ${theme.palette.secondary[40]};
    transform: translate(-150%, 40%);
  `,
  textWrapper: (theme) => css`
    margin-top: ${theme.spacing(6)};
  `,
  text: (theme) => css`
    color: ${theme.palette.secondary[40]};
    text-align: center;
  `,
};

export default styles;
