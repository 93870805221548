import { INavigationEntry } from 'types/types';
import React, { createContext, useReducer } from 'react';

interface INavigationState {
  state: {
    entries: Array<INavigationEntry>;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
}

const initialState = {
  entries: [],
};
const NavigationContext = createContext<INavigationState>({
  state: initialState,
  dispatch: () => null,
});

const MAXIMUM_NUMBER_OF_ENTRIES = 4;
const NAVIGATION_ACTION_TYPES = {
  addNavigationEntry: 'ADD_ENTRY',
  startNavigation: 'START_NAVIGATION',
};

const AddNavigationEntry = (
  entries: Array<INavigationEntry>,
  entry: INavigationEntry
): Array<INavigationEntry> => {
  const newEntries = [...entries];

  if (newEntries.length === MAXIMUM_NUMBER_OF_ENTRIES) {
    newEntries.shift();
  }

  newEntries.push(entry);

  return newEntries;
};

const reducer = (state, action) => {
  switch (action.type) {
    case NAVIGATION_ACTION_TYPES.addNavigationEntry:
      return {
        ...state,
        entries: [...AddNavigationEntry(state.entries, action.payload)],
      };
    case NAVIGATION_ACTION_TYPES.startNavigation:
      return {
        ...state,
        entries: [action.payload],
      };
    default:
      throw new Error('No matching action type');
  }
};

type NavigationProviderPropsType = {
  children?: JSX.Element;
};

const NavigationProvider = (props: NavigationProviderPropsType) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <NavigationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export {
  NavigationContext,
  NavigationProvider,
  NAVIGATION_ACTION_TYPES,
  INavigationState,
};
