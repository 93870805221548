import React, { useEffect, useState } from 'react';
// import { NavigationProvider } from 'contexts/navigationContext';
// import { IConfig } from 'types/config';
// import otherApi from 'api/configApi';
// import axiosClient from 'api/axiosClient';
// import Router from 'router/router';

import { NavigationProvider } from 'contexts/navigationContext';
import { IConfig } from 'types/config';
import otherApi from 'api/configApi';
import axiosClient from 'api/axiosClient';
import Router from 'router/router';

const App: React.FC = () => {
  const [config, setConfig] = useState<IConfig>(null);

  const getOktaConfig = async (): Promise<void> => {
    try {
      const { data } = await otherApi.getConfig();
      axiosClient.defaults.baseURL = data.API_END_POINT;
      setConfig(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getOktaConfig();
  }, []);

  if (!config) return <></>;

  return (
    <NavigationProvider>
      <Router config={config} />
    </NavigationProvider>
  );
};

export default App;
