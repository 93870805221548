import axios, { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10 * 60 * 1000,
});

axiosClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const { accessToken } = JSON.parse(
      localStorage.getItem('okta-token-storage') || '{}'
    );

    if (accessToken?.accessToken) {
      config.headers = {
        ...config.headers,
        'Authorization': `${accessToken.tokenType} ${accessToken.accessToken}`,
      } as AxiosRequestHeaders;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
