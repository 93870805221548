export interface ICustomerSearch {
  page: number;
  size: number;
  total: number;
  entities: Array<ICustomerLightDto>;
}
export interface ICustomerLightDto {
  customerNumber: number;
  customerLink: number;
  type: string;
  title: string;
  firstName: string;
  lastName: string;
  address: AddressDto;
  establishment: string;
  contact: ContactDto;
  nbPolicesActives: number;
}

export interface AddressDto {
  address1: string;
  address2: string;
  address3: string;
  zipCode: string;
  city: string;
  country: string;
  province: string;
}

export interface ContactDto {
  mail: string;
  phone1: string;
  phone2: string;
  fax: string;
  mobilePhone: string;
}

export interface IndividualDto {
  birthDate: Date;
  sex: string;
  familySituation: string;
  profession: string;
  phone1: string;
  phone2: string;
  flatmates: Array<string>;
  children: Array<string>;
  coinsured: Array<string>;
}

export interface FollowUpDto {
  creationDate: Date;
  avenant: number;
  avenantDate: Date;
  lastUpdateDate: Date;
  creationUser: string;
  lastUpdateUser: string;
  activeStatus: boolean;
  lastAction: string;
}

export interface FonciaOfficeInformationDto {
  fonciaRegion: string;
  groupingFirm: string;
  chairman: string;
  chairmanPhone: string;
  generalManager: string;
  generalManagerPhone: string;
  agencyDirector: string;
  administrativeDirectorMail: string;
  officePreviousName: string;
  nameChangeDate: Date;
}

export interface CompanyDto {
  creationDate: Date;
  legalForm: string;
  numberOfEmployees: string;
  ca: string;
  activity: string;
  siret: string;
}

export interface InformationDto {
  accountManager: string;
  accountingContact: string;
  sinisterContact: string;
  commercialContact: string;
}

export interface ICustomerDto extends ICustomerLightDto {
  socRef: string;
  agencyNumber: number;
  group: string;
  customerQuality: string;
  customerOldNum: string;
  origin: string;
  lastName2: string;
  individual: IndividualDto;
  fonciaOfficeInformation: FonciaOfficeInformationDto;
  notes: string;
  followUp: FollowUpDto;
  company: CompanyDto;
  information: InformationDto;
  rum: string;
  cnilExclusion: number;
  unsubcribeKey: string;
  villeNais: string;
  paysNais: string;
  finscanStatus: string;
  finscanDate: Date;
  dateCre: Date;
}

export interface FinscanInputDto {
  gender?: string;
  fullName: string;
  birthDate?: Date;
  countryBirth?: string;
  source: string;
}

export interface FinscanOutputDto {
  status: 'PASS' | 'PENDING' | 'FAIL' | 'ERROR' | 'UNINITIALIZED';
  message: string;
}

export interface StatGroupByResult {
  code: number;
  label: string;
  total: number;
}

export enum statsTypesEnum {
  claimOfCustomer = 'CLAIM_OF_CUSTOMER',
  policeOfCustomer = 'POLICE_OF_CUSTOMER',
  claimOfPolicy = 'CLAIM_OF_POLICY',
  quittanceOfCustomer = 'QUITTANCE_OF_CUSTOMER',
}

export enum statsStatusesEnum {
  active = 'ACTIVE',
  current = 'CURRENT',
  all = 'ALL',
  close = 'CLOSE',
}

export enum statsGroupByEnum {
  byTypePolicy = 'BY_TYPE_POLICY',
  byYearOld = 'BY_YEAR_OLD',
}
export interface StatInputDto {
  status: statsStatusesEnum;
  typeStat: statsTypesEnum;
  groupBy?: statsGroupByEnum;
  lessYearOld?: number;
}

export interface StatOutputDto {
  size: number;
  groupByResult?: Array<StatGroupByResult>;
}
