import { ITab } from 'types/types';
import ClientSummary from './Summary/summary';
import AdditionalInfos from './AdditionalInfos/additionalInfos';
import Sinisters from './Sinisters/sinisters';
import Risks from './Risks/risks';

const tabItemsIds = {
  SUMMARY: 'summary',
  ADDITIONAL_INFOS: 'additionalInfos',
  SINISTERS: 'sinisters',
  RISKS: 'risks',
  RECEIPTS: 'receipts',
  QUOTES: 'quotes',
  GED: 'ged',
};

const tabItems: Array<ITab> = [
  {
    id: tabItemsIds.SUMMARY,
    label: 'Résumé',
    component: ClientSummary,
    getNbElements: () => null,
  },
  {
    id: tabItemsIds.ADDITIONAL_INFOS,
    label: 'Informations complémentaires',
    component: AdditionalInfos,
    getNbElements: () => null,
  },
  {
    id: tabItemsIds.SINISTERS,
    label: 'Sinistres',
    component: Sinisters,
    getNbElements: () => null,
  },
  {
    id: tabItemsIds.RISKS,
    label: 'Risques',
    component: Risks,
    getNbElements: () => null,
  },
  {
    id: tabItemsIds.RECEIPTS,
    label: 'Quittances',
    component: ClientSummary,
    getNbElements: () => null,
  },
  {
    id: tabItemsIds.QUOTES,
    label: 'Devis',
    component: ClientSummary,
    getNbElements: () => null,
  },
  {
    id: tabItemsIds.GED,
    label: 'Ged',
    component: ClientSummary,
    getNbElements: () => null,
  },
];

export default tabItems;
