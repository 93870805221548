/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Home03Icon from 'components/Icons/home03';
import ChevronRightIcon from '../Icons/chevronRight';
import { INavigationEntry } from 'types/types';
import styles from './style.js';

type BreadcrumbProps = {
  navigationEntries: Array<INavigationEntry>;
};

const Breadcrumb = (props: BreadcrumbProps) => {
  const { navigationEntries } = props;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      // separator={<ChevronRightIcon css={styles.icon} />}
      separator={'>'}
    >
      <Link
        underline="hover"
        sx={{ display: 'flex', alignItems: 'center' }}
        href="/"
      >
        <Home03Icon css={styles.icon} />
      </Link>
      {navigationEntries.map((entry: INavigationEntry) => {
        return (
          <Link
            key={entry.id}
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href={entry.link}
          >
            <Typography variant={'subtitle3'} css={styles.linkText}>
              {entry.label}
            </Typography>
            {entry.parameter && (
              <Typography variant={'subtitle3'} css={styles.linkText}>
                {' '}
                : {entry.parameter}
              </Typography>
            )}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
