import React from 'react';

const Home: React.FC = () => {
  return (
    <div>
      Bienvenue sur la page Home
      <br />
      <br />
    </div>
  );
};

export default Home;
