import ClientSummary from './Summary/summary';
import ClientReceipts from './Receipts/receipts';
import ClientClaims from './Claims/claims';
import ClientPolicies from './Policies/policies';
import ClientGed from './Ged/ged';
import clientApi from 'api/customerApi';
import {
  ICustomerDto,
  statsStatusesEnum,
  statsTypesEnum,
} from 'types/client';
import { ITab } from 'types/types';

const getNbActivePolicies = async (
  customerNumber: number,
  typeStat: statsTypesEnum
): Promise<number> => {
  try {
    const stats = await clientApi.getCustomerStats(customerNumber, {
      status: statsStatusesEnum.active,
      typeStat: typeStat,
    });
    return stats.size;
  } catch (error) {
    console.log('Error - Get Number Policies per Tab', error);
    return null;
  }
};

const tabItemsIds = {
  SUMMARY: 'summary',
  POLICIES: 'policies',
  CLAIMS: 'claims',
  RECEIPTS: 'receipts',
  GED: 'ged',
};

const getTabItems = (client: ICustomerDto): Array<ITab> => [
  {
    id: tabItemsIds.SUMMARY,
    label: 'Résumé',
    component: ClientSummary,
    getNbElements: () => null,
  },
  {
    id: tabItemsIds.POLICIES,
    label: 'Polices',
    component: ClientPolicies,
    getNbElements: () =>
      getNbActivePolicies(
        client.customerNumber,
        statsTypesEnum.policeOfCustomer
      ),
  },
  {
    id: tabItemsIds.CLAIMS,
    label: 'Sinistres',
    component: ClientClaims,
    getNbElements: () =>
      getNbActivePolicies(
        client.customerNumber,
        statsTypesEnum.claimOfCustomer
      ),
  },
  {
    id: tabItemsIds.RECEIPTS,
    label: 'Quittances',
    component: ClientReceipts,
    getNbElements: () =>
      getNbActivePolicies(
        client.customerNumber,
        statsTypesEnum.quittanceOfCustomer
      ),
  },
  {
    id: tabItemsIds.GED,
    label: 'Ged',
    component: ClientGed,
    getNbElements: () => null,
  },
];

export default getTabItems;
export { tabItemsIds };
