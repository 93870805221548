import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

const defaultCellValue = '-';
const columns: Array<GridColDef> = [
  {
    field: 'police',
    headerName: 'Police',
    valueGetter: (params) => params.row.police || defaultCellValue,
    flex: 1,
  },
  {
    field: 'numQuittance',
    headerName: 'Quittance',
    valueGetter: (params) => params.row.numQuittance || defaultCellValue,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    valueGetter: (params) => params.row.type.label || defaultCellValue,
    flex: 1,
  },
  {
    field: 'montant',
    headerName: 'Montant',
    valueGetter: (params) => params.row.montant || defaultCellValue,
    flex: 1,
  },
  {
    field: 'statut',
    headerName: 'Statut',
    valueGetter: (params) => params.row.statut.code || defaultCellValue,
    flex: 1,
  },
  {
    field: 'dateDebut',
    headerName: 'Date début',
    valueGetter: (params) =>
      moment(params.row.dateDebut).format('DD/MM/YYYY') || defaultCellValue,
    flex: 0.8,
  },
  {
    field: 'quittance',
    headerName: 'Quittance',
    valueGetter: () => defaultCellValue,
    flex: 0.8,
  },
];

export default columns;
