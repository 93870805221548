/** @jsxImportSource @emotion/react */
// /** @jsxRuntime classic */
// /** @jsxRuntime automatic */

import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

import { IMenuItem } from 'types/types';
import styles from './style';

const ListItem: React.FC<IMenuItem> = (item: IMenuItem) => {
  const { label, icon, active, onClick } = item;
  const theme = useTheme();
  const Icon = icon;
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleClick = (): void => {
    onClick(item);
  };

  const handleMouseEnter = (): void => {
    setIsHover(true);
  };

  const handleMouseLeave = (): void => {
    setIsHover(false);
  };

  return (
    <ListItemButton
      css={styles.listItemButton(theme, active)}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItemIcon css={styles.listItemIcon}>
        <Icon css={styles.icon(theme, active, isHover)} />
      </ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          variant: 'body2',
          color: theme.palette.secondary[80],
        }}
        css={styles.listItemText(theme, active, isHover)}
      />
    </ListItemButton>
  );
};

export default ListItem;
