import React, { useState } from 'react';
import { Grid } from '@mui/material';

import { ICustomerDto } from 'types/client';
import customerApi from 'api/customerApi';
import {
  ComplianceIcon,
  CardIcon,
  UserRectangulaireIcon,
} from 'components/Icons/icons';
import SectionInformation from 'components/SectionInformation/sectionInformation';
import getGeneralSectionFields from './generalSectionFields';
import paymentSectionFields from './paymentSectionFields';
import relationSectionFields from './relationSectionFields';
import getComplianceSectionFields from './complianceSectionFields';
import SummarySkeleton from './summary.skeleton';

interface SummaryProps {
  entity: ICustomerDto;
}

const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
  const { entity: client } = props;

  const [loading, setLoading] = useState<boolean>(false);
  // const theme = useTheme();

  const handleRefreshFinscan = async () => {
    try {
      setLoading(false);

      // const finscanResponse = await customerApi.finscan(client.customerNumber, {
      await customerApi.finscan(client.customerNumber, {
        birthDate: client.individual.birthDate,
        countryBirth: client.paysNais,
        fullName: `${client.firstName} ${client.lastName}`,
        gender: getFinscanGender(),
        source: 'mrh',
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const getFinscanGender = () => {
    if (client.title === 'M.') return 'Male';
    else if (client.title === 'MME') return 'Female';
  };

  if (loading) return <SummarySkeleton />;
  else
    return (
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <SectionInformation
            label="Général"
            rows={getGeneralSectionFields(client)}
            icon={<UserRectangulaireIcon viewBox="0 0 22 22" />}
          />
        </Grid>
        <Grid item xs={6}>
          <SectionInformation
            label="Compliance"
            rows={getComplianceSectionFields(client, handleRefreshFinscan)}
            icon={<ComplianceIcon viewBox="0 0 22 20" />}
          />
        </Grid>
        <Grid item xs={6}>
          <SectionInformation
            label="Moyen de paiement"
            rows={paymentSectionFields}
            icon={<CardIcon viewBox="0 0 22 16" />}
          />
        </Grid>
        <Grid item xs={6}>
          <SectionInformation
            label="Relation"
            rows={relationSectionFields}
            icon={<CardIcon viewBox="0 0 22 16" />}
          />
        </Grid>
      </Grid>
    );
};

export default Summary;
