import { IConfig } from 'types/config';
import axios from 'axios';

interface ConfigResponse {
  data: IConfig;
}

const configApi = {
  async getConfig(): Promise<ConfigResponse> {
    const url = '/conf.json';
    return axios.get(url);
  },
};

export default configApi;
