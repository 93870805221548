import React, { useState } from 'react';
import Table from 'components/Table/table';
import { IPolicyLight, IPolicyLightDto } from 'types/policy';
import File06Icon from 'components/Icons/file06';
import clientApi from 'api/customerApi';
import { ICustomerDto } from 'types/client';
import columns from './columns';

const NO_RESULT_MESSAGE = 'Aucune police pour ce client';

interface IPoliciesProps {
  entity: ICustomerDto;
}

const Policies: React.FC = (props: IPoliciesProps) => {
  const { entity: client } = props;
  const [policies, setPolicies] = useState<Array<IPolicyLight>>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const getRowId = (row: IPolicyLight) => row.policyNumber;

  const getPolicies = async (currentPage, pageSize) => {
    setLoading(true);
    setPolicies([]);

    try {
      const policiesResponse: IPolicyLightDto =
        await clientApi.getPoliciesByCustomerNumber(
          client.customerNumber,
          currentPage,
          pageSize
        );
      setPolicies(policiesResponse.entities);
      setTotal(policiesResponse.total);
    } catch (error) {
      console.log('ERROR - GetPoliciesByCustomerNumber', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Table
      columns={columns}
      rows={policies || []}
      getRows={getPolicies}
      getRowId={getRowId}
      totalRows={total}
      noResultMessage={NO_RESULT_MESSAGE}
      noResultIcon={File06Icon}
      loading={loading}
    />
  );
};

export default Policies;
