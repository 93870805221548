import { css } from '@emotion/react';

const styles = {
  error: (theme) =>
    css`
      height: 24;
      background-color: ${theme.palette.error[10]};
      color: ${theme.palette.error[70]};
    `,
  warning: (theme) =>
    css`
      height: 24;
      background-color: ${theme.palette.warning[10]};
      color: ${theme.palette.warning[80]};
    `,
  success: (theme) =>
    css`
      height: 24;
      background-color: ${theme.palette.success[30]};
      color: ${theme.palette.white.main};
    `,
  ellipsisError: (theme) =>
    css`
      background-color: ${theme.palette.error[70]};
      width: 10px;
      height: 10px;
      border-radius: 50%;
    `,
  ellipsisWarning: (theme) =>
    css`
      background-color: ${theme.palette.warning[80]};
      width: 10px;
      height: 10px;
      border-radius: 50%;
    `,
  ellipsisSuccess: (theme) =>
    css`
      background-color: ${theme.palette.success[70]};
      width: 10px;
      height: 10px;
      border-radius: 50%;
    `,
};

export default styles;
