/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { firstLetterUppercase } from 'utils/utils';

import styles from './style.js';

interface IDotProps {
  variant: 'error' | 'warning' | 'success';
  label: string;
}

const Dot: React.FC<IDotProps> = (props: IDotProps) => {
  const { variant, label } = props;

  return (
    <Chip
      label={
        <Typography variant="metadata">
          {firstLetterUppercase(label)}
        </Typography>
      }
      icon={<div css={styles[`ellipsis${firstLetterUppercase(variant)}`]} />}
      sx={styles[variant]}
    />
  );
};

export default Dot;
