/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React from 'react';
import styles from './style.js';

const Breadcrumb = () => {
  return <div css={styles.ellipsis} />;
};

export default Breadcrumb;
