/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import * as React from 'react';
import MuiTabs from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { ITab, ObjectEntity } from 'types/types';
import styles from './style';
import useQuery from 'hooks/useQuery';

type TabsProps = {
  tabs: Array<ITab>;
  entity?: ObjectEntity;
};

interface ObjectEntityProps {
  entity: ObjectEntity;
}

const getInitialTab = (
  query: URLSearchParams,
  tabs: ITab[],
  location,
  history
) => {
  const queryTabParam = query.get('tab');
  if (queryTabParam) {
    const currentTab = tabs.find((tab) => tab.id === queryTabParam);
    if (currentTab?.id) return currentTab.id;
    else
      history.push({
        pathname: location.pathname,
        search: `?tab=${tabs[0].id}`,
      });
  }
  return tabs[0].id;
};

const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  const { tabs, entity } = props;
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const [currentTabId, setCurrentTabId] = React.useState(
    getInitialTab(query, tabs, location, history)
  );
  const [tabItems, setTabItems] = React.useState<ITab[]>([]);
  const currentTab = tabs.find((tab) => tab.id === currentTabId);
  const Component: React.FC<ObjectEntityProps> = currentTab.component;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabId(newValue);
  };

  const getIcon = (nbElements) => {
    return (
      <Badge
        css={styles.badge}
        badgeContent={nbElements}
        color="secondary"
        invisible={!nbElements}
      />
    );
  };

  const getNumberOfItemsPerTab = async () => {
    const newTabs = await Promise.all(
      tabs.map(async (tab) => {
        const nbItems = await tab.getNbElements();
        const newItem = { ...tab, nbElements: nbItems };
        return newItem;
      })
    );

    setTabItems(newTabs);
  };

  React.useEffect(() => {
    getNumberOfItemsPerTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box css={styles.tabsWrapper}>
        <MuiTabs value={currentTabId} onChange={handleChange} css={styles.tabs}>
          {tabItems.map((tab) => {
            return (
              <Tab
                key={tab.id}
                label={tab.label}
                css={styles.tab}
                icon={getIcon(tab.nbElements)}
                iconPosition="end"
                value={tab.id}
                to={`${location.pathname}?tab=${tab.id}`}
                component={Link}
              />
            );
          })}
        </MuiTabs>
      </Box>
      <Box>
        <Component entity={entity} />
      </Box>
    </Box>
  );
};

export default Tabs;
