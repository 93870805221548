import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import customerApi from 'api/customerApi';
import ObjectLayout from 'layouts/Object/objectLayout';
import Table from 'components/Table/table';
import UserCircle from 'components/Icons/userCircle';
import { ICustomerLightDto, ICustomerSearch } from 'types/client';
import { getAddress, isNumeric, pushIfNotExists } from 'utils/utils';
import getColumns from './columns';
import useQuery from 'hooks/useQuery';
import { ISearchParam } from 'types/types';
import { GridRowParams } from '@mui/x-data-grid';
import { objects } from 'constants/constants';
import userParamsApi from 'api/userParams';

const NO_RESULT_MESSAGE = {
  search: 'Aucun client ne correspond à votre recherche',
  history: 'Aucun client à afficher',
};

const ClientSearch: React.FC = () => {
  const query = useQuery();
  const searchParam = query.get('search');
  const history = useHistory();

  const [clients, setClients] = useState<ICustomerLightDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [tableTitle, setTableTitle] = useState<string>(null);
  const [noResultMessage, setNoResultMessage] = useState<string>(
    NO_RESULT_MESSAGE.search
  );

  const getRowId = (row: ICustomerLightDto) => row.customerNumber;

  const handleRowClick = (params: GridRowParams) => {
    const customerNumber = params?.row?.customerNumber;
    if (customerNumber)
      history.push(`${objects.CLIENTS.link}/${customerNumber}`);
  };

  const getParams = (): Array<ISearchParam> => {
    if (isNumeric(searchParam)) {
      // if numeric search on phoneNumber or customerNumber
      return [
        {
          key: 'CUSTOMER_NUMBER',
          value: [
            {
              operation: 'EQUALS',
              value1: searchParam,
            },
          ],
        },
        // {
        //   key: 'CUSTOMER_PHONE',
        //   value: [
        //     {
        //       operation: 'CONTAINS',
        //       value1: searchParam,
        //     },
        //   ],
        // },
      ];
    }
    // else if (searchParam.includes('@')) {}
    else {
      // default case search on lastName and email
      return [
        {
          key: 'CUSTOMER_LASTNAME',
          value: [
            {
              operation: 'CONTAINS',
              value1: searchParam,
            },
          ],
        },
      ];
    }

    // return [
    //   {
    //     key: 'CUSTOMER_NUMBER',
    //     value: [
    //       {
    //         operation: 'EQUALS',
    //         value1: '100881402',
    //       },
    //     ],
    //   },
    // ];
  };

  const manageResult = (response: ICustomerSearch) => {
    const { entities, total } = response;
    setClients(entities);
    setTotal(total);

    if (entities.length === 1) {
      history.push(`/clients/${entities[0].customerNumber}`);
    }
  };

  const getClients = async (currentPage?: number, pageSize?: number) => {
    setClients([]);

    if (searchParam) {
      try {
        setLoading(true);
        const clientsResponse: ICustomerSearch = await customerApi.search(
          getParams(),
          currentPage,
          pageSize
        );
        manageResult(clientsResponse);
      } catch (error) {
        console.log('ERROR - Client Search', error);
      }
    }
    setLoading(false);
  };

  const getFiltersValues = () => {
    const filtersValues = {
      lastName: [],
      firstName: [],
      address: [],
      city: [],
      zipCode: [],
      mail: [],
      customerNumber: [],
      type: [],
    };

    clients.forEach((client) => {
      if (client.lastName)
        pushIfNotExists(filtersValues.lastName, client.lastName);
      if (client.firstName)
        pushIfNotExists(filtersValues.firstName, client.firstName);
      if (client.address) {
        const address = getAddress(client.address);
        if (address) pushIfNotExists(filtersValues.address, address);
        if (client.address.city)
          pushIfNotExists(filtersValues.city, client.address.city);
        if (client.address.zipCode)
          pushIfNotExists(filtersValues.zipCode, client.address.zipCode);
      }
      if (client.contact && client.contact.mail)
        pushIfNotExists(filtersValues.mail, client.contact.mail);
      if (client.customerNumber)
        pushIfNotExists(filtersValues.customerNumber, client.customerNumber);
      if (client.type) {
        pushIfNotExists(filtersValues.type, client.type);
      }
    });

    return filtersValues;
  };

  const getClientsConsultationHistory = async () => {
    try {
      setTableTitle('Vos derniers clients consultés :');
      setNoResultMessage(NO_RESULT_MESSAGE.history);
      setLoading(true);

      const response = await userParamsApi.getHistory('clients');
      const { entities, total } = response;

      setClients(entities);
      setTotal(total);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClientsConsultationHistory();
  }, []);

  return (
    <ObjectLayout>
      <Table
        title={tableTitle}
        columns={getColumns(getFiltersValues())}
        rows={clients}
        getRows={getClients}
        getRowsTrigger={searchParam}
        getRowId={getRowId}
        totalRows={total}
        noResultMessage={noResultMessage}
        noResultIcon={UserCircle}
        onRowClick={handleRowClick}
        loading={loading}
      />
    </ObjectLayout>
  );
};

export default ClientSearch;
