import { css } from '@emotion/react';

const styles = {
  layoutContainer: (theme) => css`
    display: flex;
    flex-direction: column;
  `,
  contentWrapper: (theme) => css`
    margin-top: ${theme.spacing(5)};
  `,
};

export default styles;
