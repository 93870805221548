import { css } from '@emotion/react';

const styles = {
  listItemButton: (theme, active) =>
    css`
      height: 40;
      padding: ${theme.spacing(2)} ${theme.spacing(4)} !important;
      border-radius: 8px;
      background-color: ${active ? theme.palette.secondary.main : 'inherit'};
    `,
  listItemIcon: (theme) => css`
    min-width: 0;
    margin-right: ${theme.spacing(2)};
  `,
  icon: (theme, active, isHover) => css`
    stroke: ${active && !isHover
      ? theme.palette.white.main
      : theme.palette.secondary[80]};
  `,
  listItemText: (theme, active, isHover) => css`
    .MuiListItemText-primary {
      color: ${active && !isHover ? theme.palette.white.main : 'inherit'};
    }
    .MuiListItemText-primary:hover {
      color: inherit;
    }
  `,
};

export default styles;
