import {
  ICustomerDto,
  FinscanInputDto,
  FinscanOutputDto,
  StatOutputDto,
  StatInputDto,
  ICustomerSearch,
} from 'types/client';
import axiosClient from './axiosClient';
import { IGedCatalogue } from 'types/ged';
import { FLAG_URL, BASE_URL_CUSTOMERS } from 'api/routes';
import { IPolicyLightDto } from 'types/policy';
import { ISearchParam, ISorting } from 'types/types';
import { ICustomerClaimsOutput, IFilter } from 'types/claims';
import { ICustomerReceiptsOutput } from 'types/receipts';

const customerApi = {
  async getCustomerByCustomerNumber(
    customerNumber: number
  ): Promise<ICustomerDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}`;

    return axiosClient.get(url);
  },

  async finscan(
    customerNumber: number,
    params: FinscanInputDto
  ): Promise<FinscanOutputDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/finscan`;

    return axiosClient.post(url, params);
  },

  async getDocumentFromFlag(
    idObject: number,
    flag: string
  ): Promise<Array<IGedCatalogue>> {
    const url = `${BASE_URL_CUSTOMERS}${FLAG_URL}?idObject=${idObject}&flag=${flag}`;

    return axiosClient.get(url);
  },

  async getCustomerStats(
    customerNumber: number,
    params: StatInputDto
  ): Promise<StatOutputDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/stats`;

    return axiosClient.post(url, params);
  },

  async getPoliciesByCustomerNumber(
    customerNumber: number,
    page: number,
    size: number
  ): Promise<IPolicyLightDto> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/policies`;
    const correctPage = page + 1;

    return axiosClient.post(url, {
      page: correctPage,
      size,
    });
  },

  async getClaimsByCustomerNumber(
    customerNumber: number,
    page: number,
    size: number,
    filter?: IFilter
  ): Promise<ICustomerClaimsOutput> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/claims`;
    const correctPage = page;

    return axiosClient.post(url, {
      filter: filter || {},
      page: correctPage,
      size,
    });
  },

  async getReceiptsByCustomerNumber(
    customerNumber: number,
    page: number,
    size: number,
    filter?: IFilter
  ): Promise<ICustomerReceiptsOutput> {
    const url = `${BASE_URL_CUSTOMERS}/${customerNumber}/receipts`;
    const correctPage = page;

    return axiosClient.post(url, {
      filter: filter || {},
      page: correctPage,
      size,
    });
  },

  async search(
    params: Array<ISearchParam>,
    page: number,
    size: number,
    sortings?: Array<ISorting>
  ): Promise<ICustomerSearch> {
    const url = `${BASE_URL_CUSTOMERS}/search`;
    const correctPage = page + 1;

    return axiosClient.post(url, {
      page: correctPage,
      size,
      sortings,
      params,
    });
  },
};

export default customerApi;
