import React from 'react';
import Dot from 'components/Dot/dot';
import { ICustomerLightDto } from 'types/client';
import { GridColDef, getGridNumericOperators } from '@mui/x-data-grid';
import { getAddress } from 'utils/utils';

interface IFiltersValues {
  lastName: Array<string>;
  firstName: Array<string>;
  address: Array<string>;
  city: Array<string>;
  zipCode: Array<string>;
  mail: Array<string>;
  customerNumber: Array<string>;
  type: Array<string>;
}

const defaultCellValue = '-';

const getPhoneNumber = (row: ICustomerLightDto): string => {
  const { contact } = row;

  if (contact.mobilePhone) return contact.mobilePhone;
  else if (contact.phone1) return contact.phone1;
  else if (contact.phone2) return contact.phone2;
  else return null;
};

const renderActivePolicies = (row: ICustomerLightDto) => {
  const { nbPolicesActives: nbPolicies }: { nbPolicesActives: number } = row;

  if (nbPolicies > 0)
    return <Dot variant="success" label={`En cours (${nbPolicies})`} />;
  else if (nbPolicies <= 0)
    return <Dot variant="error" label={'En cours (0)'} />;
  else return null;
};

const getActivePolicies = (row: ICustomerLightDto) => {
  const { nbPolicesActives: nbPolicies }: { nbPolicesActives: number } = row;
  if (nbPolicies > 0) return nbPolicies;
  else return 0;
};

const getColumns = (filtersValues: IFiltersValues): Array<GridColDef> => [
  {
    field: 'title',
    headerName: 'Titre',
    valueGetter: (params) => params.row.title || defaultCellValue,
    flex: 0.4,
  },
  {
    field: 'lastName',
    headerName: 'Nom',
    valueGetter: (params) => params.row.lastName || defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.lastName,
    flex: 1,
  },
  {
    field: 'firstName',
    headerName: 'Prénom',
    valueGetter: (params) => params.row.firstName || defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.firstName,
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    valueGetter: (params) => getAddress(params.row.address) || defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.address,
    flex: 1,
  },
  {
    field: 'city',
    headerName: 'Ville',
    valueGetter: (params) =>
      params.row.address && params.row.address.city
        ? params.row.address.city
        : defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.city,
    flex: 1.2,
  },
  {
    field: 'zipCode',
    headerName: 'Code postal',
    valueGetter: (params) =>
      params.row.address && params.row.address.zipCode
        ? params.row.address.zipCode
        : defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.zipCode,
    flex: 0.5,
  },
  {
    field: 'contact.mail',
    headerName: 'Adresse mail',
    valueGetter: (params) =>
      params.row.contact && params.row.contact.mail
        ? params.row.contact.mail
        : defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.mail,
    flex: 1.5,
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    valueGetter: (params) => getPhoneNumber(params.row) || defaultCellValue,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    valueGetter: (params) => params.row.type || defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.type,
    flex: 0.2,
  },
  {
    field: 'customerNumber',
    headerName: 'Numéro',
    valueGetter: (params) => params.row.customerNumber || defaultCellValue,
    type: 'singleSelect',
    valueOptions: filtersValues.mail,
    flex: 1,
  },
  {
    field: 'nbPolicesActives',
    headerName: 'Polices actives',
    type: 'number',
    valueGetter: (params) => getActivePolicies(params.row) || defaultCellValue,
    renderCell: (params) =>
      renderActivePolicies(params.row) || defaultCellValue,
    filterOperators: getGridNumericOperators().filter(({ value }) =>
      ['=', '!=', '>', '>=', '<', '<='].includes(value)
    ),
    flex: 0.9,
  },
];

export default getColumns;
