import { SortDirectionsEnum } from 'types/types';

export enum AgendaEnum {
  inProgress = 'EN_COURS',
  notPlanned = 'NON_AGENDEE',
  noInfo = 'AUCUN_INFO',
}

export enum MovementEnum {
  closs = 'CLOSS',
  closter = 'CLOSTER',
  ec = 'EC',
  predecla = 'PREDECLA',
}

export enum SortNamesEnum {
  nsin = 'nsin',
  police = 'police',
  codeCie = 'codeCie',
  nature = 'nature',
  mouvement = 'mouvement',
  dateSin = 'dateSin',
  couteVal = 'couteVal',
  cout = 'cout',
}

export interface IFilter {
  nsins: Array<number>;
  numPolices: Array<string>;
  codeCies: Array<string>;
  nomCies: Array<string>;
  natures: Array<string>;
  mouvements: Array<
    | MovementEnum.closs
    | MovementEnum.closter
    | MovementEnum.ec
    | MovementEnum.predecla
  >;
  dateSinMin: Date;
  dateSinMax: Date;
  dateSin: Date;
  evalMin: number;
  evalMax: number;
  coutMin: number;
  coutMax: number;
  sorting: {
    sort: SortDirectionsEnum.ascending | SortDirectionsEnum.descending;
    sortName:
      | SortNamesEnum.nsin
      | SortNamesEnum.police
      | SortNamesEnum.codeCie
      | SortNamesEnum.nature
      | SortNamesEnum.mouvement
      | SortNamesEnum.dateSin
      | SortNamesEnum.couteVal
      | SortNamesEnum.cout;
  };
}

// {
//   "numQuittance": 22609274,
//   "police": "H44998P-8254-0",
//   "type": {
//     "code": "RE",
//     "label": "Règlement",
//     "description": ""
//   },
//   "montant": 31.37,
//   "statut": {
//     "code": "R",
//     "label": "Réglée",
//     "description": "Réglée"
//   },
//   "dateDebut": "2022-05-09T00:00:00Z",
//   "dateFin": "2022-06-30T00:00:00Z",
//   "quittanceDisponible": false
// },

// export interface ICustomerClaimsOutputContent {
//   numQuittance: 'number',
//   police: string
// }

export interface ICustomerClaimsOutputContent {
  agenda: AgendaEnum.inProgress | AgendaEnum.noInfo | AgendaEnum.notPlanned;
  codeCie: string;
  cout: number;
  couteVal: number;
  dateSin: Date;
  mouvement: Array<
    | MovementEnum.closs
    | MovementEnum.closter
    | MovementEnum.ec
    | MovementEnum.predecla
  >;
  nature: string;
  nomCie: string;
  nsin: number;
  police: string;
}

export interface ICustomerClaimsInput {
  filter: IFilter;
  page: number;
  size: number;
}

export interface ICustomerClaimsOutput {
  content: Array<ICustomerClaimsOutputContent>;
  number: number;
  numberOfElements: number;
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}
