import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { OktaSignInConfigType } from 'types/auth';
import { Tokens } from '@okta/okta-auth-js';

interface OktaSignInWidgetProps {
  config: OktaSignInConfigType;
  onSuccess: (tokens: Tokens) => void;
  onError: (err: any) => void;
}

const OktaSignInWidget = ({
  config,
  onSuccess,
  onError,
}: OktaSignInWidgetProps) => {
  const widgetRef = useRef();

  console.log('config', config);

  useEffect(() => {
    // Hide help text on load
    setTimeout(() => {
      const selector = document.querySelectorAll<HTMLElement>(
        '[data-se="needhelp"]'
      );
      if (selector.length) selector[0].style.display = 'none';
    }, 50);
  }, []);

  useEffect(() => {
    if (!widgetRef.current) return;
    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({ el: widgetRef.current })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
