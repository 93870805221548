import { objects } from 'constants/constants';
import { IMenuItem } from 'types/types';

const menuItems: Array<IMenuItem> = [
  objects.HOME,
  objects.TEAM_MANAGEMENT,
  objects.CLIENTS,
  objects.POLICIES,
  objects.SINISTERS,
  objects.RISKS,
  objects.RELATIONS,
  objects.MAILS,
  objects.ACCOUNTING,
  objects.QUOTES,
  objects.GED,
  objects.EXPORT,
  objects.MANAGEMENT,
];

export default menuItems;
