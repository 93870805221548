import { css } from '@emotion/react';

const styles = {
  headerLeft: (theme) => css`
    padding: ${theme.spacing(0)};
  `,
  headerTop: (theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.spacing(0)};
    flex-grow: 0;
    flex: none;
    order: 0;
    border-radius: 8px;
  `,
  headerCenter: (theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.spacing(0)};
    color: ${theme.palette.secondary[80]};
    flex-grow: 0;
    flex: none;
    order: 1;
    margin-top: 17px;
    margin-bottom: 17px;
  `,
  headerFooter: (theme) => css`
    padding: ${theme.spacing(0)};
    display: flex;
    flex-direction: row;
  `,
  chip: (theme) => css`
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.secondary[10]};
  `,
  container: (theme) => css`
    margin-right: ${theme.spacing(4)};
  `,
  headerTitle: (theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.palette.secondary[90]};
  `,
  icon: (theme) => css`
    margin-right: ${theme.spacing(2)};
    stroke: ${theme.palette.secondary.main};
  `,
  headerButton: (theme) => css`
    color: ${theme.palette.secondary.main};
    background-color: ${theme.palette.white.main};
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    flex-direction: row;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    margin-left: ${theme.spacing(2)};

    width: 94px;
    height: 40px;

    border: 1px solid ${theme.palette.secondary.main};
    border-radius: 8px;

    .MuiButton-root-Header:hover {
      background-color: ${theme.palette.white.main};
    }
  `,
  link: (theme) => css`
    color: ${theme.palette.secondary[80]};
    cursor: pointer;
    text-decoration: underline;
  `,
  alertError: () => css`
    padding: 0px;
  `,
  alertContainer: (theme) => css`
    padding: ${theme.spacing(0)};
    display: flex;
    flex-direction: column;
  `,
  iconAlert: (theme) => css`
    left: 8.33%;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;
    margin-left: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
    stroke: ${theme.palette.error.main};
  `,
};

export default styles;
