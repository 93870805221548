import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { OktaAuthConfigType, OktaSignInConfigType } from 'types/auth';
import LoginView from 'views/Login/login';
import { getOktaAuthConfig, getOktaSignInConfig } from 'utils/okta';
import { IConfig } from 'types/config';
import RoutesUnderGlobalLayout from './routesUnderGlobalLayout';

type Props = {
  config: IConfig;
};

const Router: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const history = useHistory();

  const onAuthRequired = (): void => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const oktaAuthConfig: OktaAuthConfigType = getOktaAuthConfig(
    config,
    onAuthRequired
  );

  const oktaSignInConfig: OktaSignInConfigType = getOktaSignInConfig(config);

  return (
    <Security
      oktaAuth={new OktaAuth(oktaAuthConfig)}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <Route
          exact
          path="/login"
          component={() => <LoginView oktaSignInConfig={oktaSignInConfig} />}
        />
        <Route>
          <RoutesUnderGlobalLayout />
        </Route>
      </Switch>
    </Security>
  );
};

export default Router;
