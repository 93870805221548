// import policyApi from 'api/policyApi';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Policies: React.FC = () => {
  const getPolicies = async () => {
    // await policyApi.list();
  };

  useEffect(() => {
    getPolicies();
  }, []);

  return (
    <div>
      Vous êtes sur la page Polices
      <br />
      <br />
      <Link to="/">go to Home</Link>
    </div>
  );
};

export default Policies;
