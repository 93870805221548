import React from 'react';
import { useHistory } from 'react-router-dom';

import SearchBar from 'components/SearchBar/searchBar';
import searchBarItems from './items';
import { IMenuItem } from 'types/types';

const Search: React.FC = () => {
  const history = useHistory();

  const handleSearch = (selectedItem: IMenuItem, searchValue: string) => {
    history.push(`${selectedItem.link}?search=${searchValue}`);
  };

  return <SearchBar items={searchBarItems} onSearch={handleSearch} />;
};

export default Search;
