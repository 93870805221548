/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/react';

import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/InputBase';

import { IMenuItem } from 'types/types';
import Select from 'components/Select/select';
import { SearchMdIcon } from 'components/Icons/icons';
import styles from './style';

type Props = {
  items: Array<IMenuItem>;
  onSearch: (selectedItem: IMenuItem, searchValue: string) => void;
};

const SearchBar: React.FC<Props> = (props: Props) => {
  const location = useLocation();

  const { items, onSearch } = props;
  const [searchValue, setSearchValue] = useState<string>(null);
  const [selectedItem, setSelectedItem] = useState<IMenuItem>(items[0]);

  const handleSelect = (item: IMenuItem) => {
    if (item !== selectedItem) setSearchValue(null);
    setSelectedItem(item);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleSearch = () => {
    onSearch(selectedItem, searchValue);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  const manageSelectedItem = () => {
    const newSelectedItem = items.find((item) => {
      const match = matchPath(location.pathname, {
        path: [item.link, `${item.link}/:id`],
        exact: true,
        strict: true,
      });

      return !!match;
    });

    if (newSelectedItem) setSelectedItem(newSelectedItem);
  };

  useEffect(() => {
    manageSelectedItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div css={styles.searchBar}>
      <Select
        items={items}
        selectedItem={selectedItem}
        onSelect={handleSelect}
      />
      <div css={styles.textFieldWrapper}>
        <Input
          fullWidth
          css={styles.textField}
          placeholder={selectedItem.placeholder || null}
          onChange={handleInputChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchMdIcon />
              </IconButton>
            </InputAdornment>
          }
          inputProps={{
            onKeyDown: handleKeyDown,
          }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
