import {
  BarChart07Icon,
  BookOpen02Icon,
  Building05Icon,
  CloudRaining01Icon,
  Download01Icon,
  File06Icon,
  FilePlus01Icon,
  FolderIcon,
  Home03Icon,
  Mail02Icon,
  UserCircleIcon,
  Users01Icon,
  Lock01Icon,
} from 'components/Icons/icons';

export const objects = {
  HOME: {
    id: 'home',
    label: 'Accueil',
    icon: Home03Icon,
    link: '/',
  },
  TEAM_MANAGEMENT: {
    id: 'teamManagement',
    // eslint-disable-next-line quotes
    label: "Gestion d'équipe",
    icon: Users01Icon,
    link: '/gestion-equipe',
  },
  CLIENTS: {
    id: 'clients',
    label: 'Clients',
    icon: UserCircleIcon,
    placeholder:
      'Saissiez le nom, prénom, l’adresse, le téléphone, ou le numéro du client',
    link: '/clients',
  },
  POLICIES: {
    id: 'policies',
    label: 'Polices',
    icon: File06Icon,
    link: '/polices',
  },
  SINISTERS: {
    id: 'sinisters',
    label: 'Sinistres',
    icon: CloudRaining01Icon,
    link: '/sinistres',
  },
  RISKS: {
    id: 'risks',
    label: 'Risques',
    icon: Building05Icon,
    link: '/risques',
  },
  RELATIONS: {
    id: 'relations',
    label: 'Relations',
    icon: BookOpen02Icon,
    link: '/relations',
  },
  MAILS: {
    id: 'mails',
    label: 'Courrier',
    icon: Mail02Icon,
    link: '/courrier',
  },
  ACCOUNTING: {
    id: 'accounting',
    label: 'Comptabilité',
    icon: BarChart07Icon,
    link: '/comptabilite',
  },
  QUOTES: {
    id: 'quotes',
    label: 'Devis',
    icon: FilePlus01Icon,
    link: '/devis',
  },
  GED: {
    id: 'ged',
    label: 'GED',
    icon: FolderIcon,
    link: '/ged',
  },
  EXPORT: {
    id: 'Export',
    label: 'Export',
    icon: Download01Icon,
    link: '/export',
  },
  MANAGEMENT: {
    id: 'management',
    label: 'Administration',
    icon: Lock01Icon,
    link: '/administration',
  },
};
