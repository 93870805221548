import { OktaAuthConfigType, OktaSignInConfigType } from 'types/auth';
import { IConfig } from 'types/config';

const getOktaAuthConfig = (
  config: IConfig,
  onAuthRequired: () => void
): OktaAuthConfigType => {
  if (!config) return;

  return {
    issuer: config.OKTA_ISSUER,
    clientId: config.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/login',
    onAuthRequired: onAuthRequired,
    useInteractionCodeFlow: true,
  };
};

const getOktaSignInConfig = (config: IConfig): OktaSignInConfigType => ({
  baseUrl: config.OKTA_URL,
  clientId: config.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  postLogoutRedirectUri: window.location.origin + '/login',
  language: 'fr',
  authParams: {
    issuer: config.OKTA_ISSUER,
    scopes: ['openid', 'profile', 'email'],
  },
});

export { getOktaAuthConfig, getOktaSignInConfig };
