import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import Dot from 'components/Dot/dot';
import { AgendaEnum } from 'types/claims';

const getAgenda = (value: string) => {
  if (value === AgendaEnum.inProgress)
    return <Dot variant="success" label="En cours" />;
  if (value === AgendaEnum.notPlanned)
    return <Dot variant="error" label="Non agendée" />;
  else return null;
};

const defaultCellValue = '-';
const columns: Array<GridColDef> = [
  {
    field: 'sinisterNumber',
    headerName: 'Sinistre',
    valueGetter: (params) => params.row.nsin || defaultCellValue,
    flex: 1,
  },
  {
    field: 'policyNumber',
    headerName: 'Police',
    valueGetter: (params) => params.row.police || defaultCellValue,
    flex: 1,
  },
  {
    field: 'companyCode',
    headerName: 'Code Cie',
    valueGetter: (params) => params.row.codeCie || defaultCellValue,
    flex: 0.8,
  },
  {
    field: 'companyName',
    headerName: 'Nom Cie',
    valueGetter: (params) => params.row.nomCie || defaultCellValue,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Nature',
    valueGetter: (params) => params.row.nature || defaultCellValue,
    flex: 1,
  },
  {
    field: 'agenda',
    headerName: 'Agenda',
    renderCell: (params) =>
      params.row.agenda ? getAgenda(params.row.agenda) : defaultCellValue,
    valueGetter: (params) => getAgenda(params.row.agenda),
    valueOptions: Object.values(AgendaEnum),
    flex: 1,
  },
  {
    field: 'mouvement',
    headerName: 'Mouvement',
    valueGetter: (params) => params.row.mouvement || defaultCellValue,
    flex: 1,
  },
  {
    field: 'date',
    headerName: 'Date',
    valueGetter: (params) =>
      moment(params.row.date).format('DD/MM/YYYY') || defaultCellValue,
    flex: 0.8,
  },
  {
    field: 'eval',
    headerName: 'Evaluation',
    valueGetter: (params) => params.row.coutEval || defaultCellValue,
    flex: 1,
  },
  {
    field: 'price',
    headerName: 'Coût',
    valueGetter: (params) => params.row.cout || defaultCellValue,
    flex: 1,
  },
];

export default columns;
