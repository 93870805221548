import React from 'react';
import { getGridSingleSelectOperators, GridColDef } from '@mui/x-data-grid';
import Dot from 'components/Dot/dot';

const getMovement = (statusCode: string) => {
  const twoFirstLetters = statusCode.slice(0, 2).toUpperCase();

  if (twoFirstLetters === 'RE') return <Dot variant="error" label="Résilié" />;
  if (twoFirstLetters === 'SU') return <Dot variant="error" label="Suspendu" />;
  if (statusCode === 'ANNP')
    return <Dot variant="warning" label="En attente" />;
  return <Dot variant="success" label="En cours" />;
};

const getMovementLabel = (statusCode: string) => {
  const twoFirstLetters = statusCode.slice(0, 2).toUpperCase();

  if (twoFirstLetters === 'RE') return 'Résilié';
  if (twoFirstLetters === 'SU') return 'Suspendu';
  if (statusCode === 'ANNP') return 'En attente';
  return 'En cours';
};

const defaultCellValue = '-';
const columns: Array<GridColDef> = [
  {
    field: 'policyNumber',
    headerName: 'Police',
    valueGetter: (params) => params.row.policyNumber || defaultCellValue,
    flex: 1,
  },
  {
    field: 'companyCode',
    headerName: 'Code Cie',
    valueGetter: (params) =>
      params.row.insuranceCompany.code || defaultCellValue,
    flex: 0.5,
  },
  {
    field: 'companyName',
    headerName: 'Nom Cie',
    valueGetter: (params) =>
      params.row.insuranceCompany.label || defaultCellValue,
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    valueGetter: (params) => params.row.policyKey || defaultCellValue,
    flex: 1.7,
  },
  {
    field: 'type',
    headerName: 'Type',
    valueGetter: (params) => params.row.contractType.code || defaultCellValue,
    flex: 0.3,
  },
  {
    field: 'buildingRef',
    headerName: 'Ref Immeuble Part.',
    valueGetter: () => defaultCellValue,
    flex: 1,
  },
  {
    field: 'transaction',
    headerName: 'Mouvement',
    renderCell: (params) =>
      params.row.status.code
        ? getMovement(params.row.status.code)
        : defaultCellValue,
    valueGetter: (params) => getMovementLabel(params.row.status.code),
    filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
      ['is'].includes(value)
    ),
    valueOptions: ['En cours', 'En attente', 'Résilié', 'Suspendu'],
    flex: 1,
  },
  {
    field: 'payment',
    headerName: 'Paiement',
    valueGetter: () => defaultCellValue,
    flex: 1,
  },
  {
    field: 'signedCp',
    headerName: 'Cp signés',
    valueGetter: () => defaultCellValue,
    flex: 1,
  },
];

export default columns;
