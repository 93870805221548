import { css } from '@emotion/react';

const styles = {
  select: (theme) => css`
    padding-top: ${theme.spacing(2)};
    padding-left: ${theme.spacing(4)};
    padding-right: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.secondary[30]};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: ${theme.palette.white.main};
    max-width: 198px;
  `,
  input: (theme) => css`
    & .Mui-disabled {
      cursor: pointer;
      color: ${theme.palette.secondary[80]};
      -webkit-text-fill-color: ${theme.palette.secondary[80]} !important;
    }
    ${theme.typography.body2}
  `,
  popover: (theme) =>
    css`
      & .MuiPopover-paper {
        padding: ${theme.spacing(2)};
        border-radius: 8px;
        border: 1px solid ${theme.palette.secondary[30]};
        margin-top: ${theme.spacing()};
        box-shadow: none;
      }
    `,
  popoverContent: (theme) => css``,
};

export default styles;
