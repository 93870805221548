import colors from 'theme/colors';

declare module '@mui/material/styles' {
  interface Theme {
    white: {
      main: React.CSSProperties['color'];
    };
  }

  interface PaletteColor {
    10?: string;
    20?: string;
    30?: string;
    40?: string;
    60?: string;
    70?: string;
    80?: string;
    90?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

const palette = {
  primary: {
    main: colors.purple[50],
    10: colors.purple[10],
    20: colors.purple[20],
    30: colors.purple[30],
    40: colors.purple[40],
    60: colors.purple[60],
    70: colors.purple[70],
    80: colors.purple[80],
    90: colors.purple[90],
  },
  secondary: {
    main: colors.grey[50],
    10: colors.grey[10],
    20: colors.grey[20],
    30: colors.grey[30],
    40: colors.grey[40],
    60: colors.grey[60],
    70: colors.grey[70],
    80: colors.grey[80],
    90: colors.grey[90],
  },
  success: {
    main: colors.green[50],
    10: colors.green[10],
    20: colors.green[20],
    30: colors.green[30],
    40: colors.green[40],
    60: colors.green[60],
    70: colors.green[70],
    80: colors.green[80],
    90: colors.green[90],
  },
  warning: {
    main: colors.yellow[50],
    10: colors.yellow[10],
    20: colors.yellow[20],
    30: colors.yellow[30],
    40: colors.yellow[40],
    60: colors.yellow[60],
    70: colors.yellow[70],
    80: colors.yellow[80],
    90: colors.yellow[90],
  },
  error: {
    main: colors.red[50],
    10: colors.red[10],
    20: colors.red[20],
    30: colors.red[30],
    40: colors.red[40],
    60: colors.red[60],
    70: colors.red[70],
    80: colors.red[80],
    90: colors.red[90],
  },
  white: {
    main: colors.white,
  },
};

export default palette;
