const colors = {
  white: '#fff',

  grey: {
    90: '#101323',
    80: '#293056',
    70: '#363F72',
    60: '#3E4784',
    50: '#4E5BA6',
    40: '#717BBC',
    30: '#B3B8DB',
    20: '#EAECF5',
    10: '#F8F9FC',
  },
  purple: {
    90: '#7C0000',
    80: '#941E00',
    70: '#AC3500',
    60: '#C54A00',
    50: '#F37020',
    40: '#FF8A3B',
    30: '#FFA050',
    20: '#FFB665',
    10: '#FFCD7A',
  },
  red: {
    90: '#A83018',
    80: '#B33A21',
    70: '#CE4528',
    60: '#DC4C2B',
    50: '#DC4C2B',
    40: '#F5704F',
    30: '#F68A6E',
    20: '#F8AA97',
    10: '#FACBBF',
  },
  green: {
    90: '#106132',
    80: '#1E8046',
    70: '#259151',
    60: '#2EA45E',
    50: '#36B368',
    40: '#58BF7E',
    30: '#77CA95',
    20: '#9FD8B3',
    10: '#C4E7D0',
  },
  yellow: {
    90: '#FF9100',
    80: '#FF9100',
    70: '#FFA200',
    60: '#FFB500',
    50: '#FFC400',
    40: '#FFCC26',
    30: '#FFD74F',
    20: '#FFE182',
    10: '#FFEDB3',
  },
};

export default colors;
